import { track } from 'Analytics';
import { ConversationalForm } from 'conversational-form';
import 'form.scss';
import _ from 'lodash';
import React from 'react';
import {
  BlueResponseTag,
  BooleanTag,
  DouleurScaleTag,
  DouleurTag,
  OrangeResponseTag,
  RedResponseTag,
  RobotTag,
  SelectTag,
  TextInputTag,
} from 'Tags';

const exitGroup = (condition = { and: {}, or: {} }) => {
  return [
    new DouleurTag('douleur', 'Je vois.&&Avez-vous mal :', condition).render(),
    new DouleurScaleTag(
      'echelle_douleur',
      'Sur une échelle de 1 à 10 à combien estimez-vous votre douleur ?&&(1 pas de douleur et 10 étant la douleur la plus insupportable que vous pouvez imaginer)',
      {
        and: {
          ...condition.and,
          douleur: 'tout_le_temps||temps_en_temps',
        },
      }
    ).render(),

    new BooleanTag(
      'fatigue',
      'Sentez vous une fatigue générale importante ces derniers jours ?',
      condition
    ).render(),
    new BooleanTag(
      'appetit',
      'Est ce que vous avez beaucoup moins d’appétit que d’habitude ?',
      condition
    ).render(),
    new BooleanTag(
      'poids',
      'Avez-vous constaté une perte de poids liée à votre état ces derniers jours ?',
      condition
    ).render(),
    new BooleanTag('fievre', 'Avez-vous de la fièvre ?', condition).render(),
  ];
};

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.formFields = [
      {
        tag: 'select',
        // 'type': 'radio',
        name: 'start',
        'cf-questions':
          'Bonjour, je suis le robot d’aiguillage des urgences dentaires.&&Je vais essayer de cerner votre demande pour vous adresser aux personnes qui vous prendront en charge.',
        children: [
          {
            tag: 'option',
            'cf-label': 'Entendu',
          },
        ],
      },

      new SelectTag('kind', 'Votre demande concerne :', {}, [
        {
          label: 'Une dent cassée ou une obturation (plombage) qui est tombée',
          value: 'dent_cassee',
        },
        {
          label: 'Un traumatisme suite à un choc ou une chute',
          value: 'traumatisme',
        },
        {
          label: 'Un appareil, une couronne, bridge ou tout autre prothèse',
          value: 'prothese',
        },
        {
          label: 'Des symptômes (douleur, rougeur, gonflement, saignement, etc.)',
          value: 'symptomes',
        },
      ]).render(),

      // ...exitGroup({and: {kind: 'dent_casse'}}),
      // Kind dent_cassee
      // new BooleanTag('dent_cassee', 'Avez-vous une ou plusieurs dents cassées ?', {and: {kind :'dent_cassee' }}).render(),

      //   new BooleanTag('saignement_dent_cassee', 'Saignez-vous ?', {dent_cassee : true }).render(),

      // Kind prothese
      new BooleanTag('appareil_casse', 'Votre appareil est-il cassé ?', {
        and: { kind: 'prothese' },
      }).render(),

      new BooleanTag(
        'prothese_tombee',
        'Votre couronne, bridge ou autre prothèse (provisoire ou définitive) est-elle tombée ou cassée ?',
        { and: { kind: 'prothese', appareil_casse: false } }
      ).render(),

      new BooleanTag('dent_devant', 'Cela concerne-t-il une dent de devant ?', {
        and: { prothese_tombee: true },
      }).render(),

      // Symptomes
      new BooleanTag('enfle', 'Etes vous enflé ?', {
        and: { kind: 'symptomes||dent_cassee||traumatisme' },
      }).render(),
      new BooleanTag(
        'gonflement_etendu',
        'Le gonflement s’étend-t-il au dessus de l’oeil ou dans le cou ?',
        { and: { enfle: true } }
      ).render(),

      new BooleanTag('saignement', 'Saignez-vous ?', {
        and: { enfle: 'false' },
      }).render(),
      new BooleanTag('saignement_choc', 'Saignez vous suite à une intervention ou un choc ?', {
        and: { saignement: true },
      }).render(),

      new BooleanTag('choc', 'Avez-vous subi un choc violent (chute ou coup)  ?', {
        and: { saignement: false },
      }).render(),
      new BooleanTag('dent_casse', 'Avez-vous une dent cassée ou qui bouge ? ', {
        and: { choc: true, kind: 'symptomes||dent_cassee||traumatisme' },
      }).render(),
      new BooleanTag('dent_expulse', 'Avez-vous une dent expulsée ?', {
        and: { choc: true, kind: 'symptomes||dent_cassee' },
      }).render(),

      ...exitGroup(),
    ];

    this.submitCallback = this.submitCallback.bind(this);
    this.onStepCallback = this.onStepCallback.bind(this);
    this.state = {
      load_timestamp: new Date().toISOString(),
      start_timestamp: undefined,
    };
  }

  componentDidMount() {
    this.cf = ConversationalForm.startTheConversation({
      options: {
        submitCallback: this.submitCallback,
        flowStepCallback: this.onStepCallback,
        preventAutoFocus: true,
        // loadExternalStyleSheet: false
        robotImage: 'doctor.png',
        userImage: 'user.svg',
        dictionaryData: {
          'input-placeholder': 'Veuillez entrer votre réponse...',
          'input-placeholder-error': 'Réponse non valide',
        },
      },
      tags: this.formFields,
    });
    this.elem.appendChild(this.cf.el);
  }

  onStepCallback(dto, success, error) {
    this.setState({
      ...this.state,
      start_timestamp: new Date().toISOString(),
    });
    if (dto.tag.name === 'start') {
      track('start', { load_timestamp: this.state.load_timestamp });
    }

    if (dto.tag.name === 'fievre') {
      const formData = this.cf.getFormData(true);
      _.each(formData, function (value, key) {
        formData[key] = value[0];
        if (key === 'echelle_douleur') {
          formData[key] = parseInt(value[0], 10);
        }
      });

      let responseTag = undefined;
      let responseType = undefined;
      if (
        [6, 7, 8, 9].includes(formData.echelle_douleur) ||
        formData.gonflement_etendu === 'true' ||
        formData.saignement_choc === 'true'
      ) {
        // addTag(this.cf, );
        responseTag = new RedResponseTag().render();
        responseType = 'red';
      } else if (
        [3, 4, 5].includes(formData.echelle_douleur) ||
        formData.fatigue === 'true' ||
        formData.appetit === 'true' ||
        formData.poids === 'true' ||
        formData.fievre === 'true' ||
        formData.enfle === 'true' ||
        formData.saignement === 'true' ||
        formData.choc === 'true'
      ) {
        // addTag(this.cf, new OrangeResponseTag().render());
        responseTag = new OrangeResponseTag().render();
        responseType = 'orange';
      } else {
        // addTag(this.cf, new BlueResponseTag().render());
        responseTag = new BlueResponseTag().render();
        responseType = 'blue';
      }
      this.setState({
        ...this.state,
        responseType,
      });

      const tags = [
        responseTag,
        new TextInputTag(
          'departement',
          'Quel est votre numéro de département ?',
          {},
          'Numéro de département',
          'Numéro de département incorrect'
        ).render(),

        new BooleanTag('dentiste_traitant', 'Avez-vous un dentiste traitant ?', {}).render(),

        new RobotTag(
          'blue_phone',
          'Il est recommandé pour faciliter votre prise en charge de contacter en priorité votre dentiste traitant.',
          { and: { dentiste_traitant: 'true' } }
        ).render(),

        new BooleanTag('contact_succes', 'Avez-vous réussi à joindre votre dentiste traitant ?', {
          and: { dentiste_traitant: 'true' },
        }).render(),
        new RobotTag('parfait', 'Parfait, je vous souhaite une bonne journée.', {
          and: { contact_succes: 'true' },
        }).render(),
      ];
      this.cf.addTags(tags, true);
      return success();
    }

    if (dto.tag.name === 'departement') {
      const departement = dto.tag.value.toLowerCase();

      if (departements.includes(departement)) {
        this.setState({
          ...this.state,
          departement,
        });
        return success();
      } else {
        return error();
      }
    }

    if (
      (dto.tag.name === 'dentiste_traitant' && dto.tag.value[0] === 'false') ||
      (dto.tag.name === 'contact_succes' && dto.tag.value[0] === 'false')
    ) {
      let dep = this.state.departement;

      if (dep.startsWith('0')) {
        dep = dep.substr(1);
      }
      // const phone = departementNum[dep] ? departementNum[dep] : NationalNum;
      // const kind = departementNum[dep] ? 'départemental' : 'national';





      // const messageMap = {
      //   blue: `En cas de doute ou de question supplémentaire, vous pouvez contacter le numéro ${kind} suivant:<br/><b><a href="tel:${phone}">${phone}</a></b>. Vous serez renseigné par un interlocuteur qui devrait pouvoir répondre à vos questions.`,
      //   orange: `Veuillez contacter le numéro ${kind} suivant : <b><a href="tel:${phone}">${phone}</a></b>. Vous serez mis en relation avec un praticien qui pourra juger de votre situation et faire le nécessaire pour votre prise en charge.`,
      //   red: `Veuillez contacter le numéro ${kind} suivant : <b><a href="tel:${phone}">${phone}</a></b>. Votre prise en charge auprès d'un praticien de garde sera organisée dans les plus brefs délais.`,
      // };

      // const message = this.state.responseType
      //   ? messageMap[this.state.responseType]
      //   : messageMap['orange'];


      var d = new Date();
      var dayOfWeek = d.getDay();
      var isWeekDay = dayOfWeek != 7;

      var message;

      if (isWeekDay) {
        message = `Essayez de contacter un dentiste près de chez vous.&&Si aucun dentiste n’est disponible pour vous recevoir, vous pouvez vous rendre dans un service hospitalier dentaire à proximité.`;
      } else {
        message = `Les cabinets dentaires libéraux ne sont pas ouverts le dimanche.&&Pour connaître les dentistes de garde, consultez le site du conseil de l'ordre départemental des chirurgiens dentistes ou bien contactez le par téléphone.&&Si aucun dentiste n’est disponible pour vous recevoir : vous pouvez vous rendre dans un service hospitalier dentaire à proximité ou bien aux urgences générales d’un hôpital.`
      }


      this.cf.addTags([new RobotTag(`${this.state.responseType}_phone`, message).render()], true);

      return success();
    }
    return success();
  }

  submitCallback() {
    const formData = this.cf.getFormData(true);
    _.each(formData, function (value, key) {
      formData[key] = value[0];
      if (key === 'echelle_douleur') {
        formData[key] = parseInt(value[0], 10);
      }
      if (key === 'departement') {
        formData[key] = value;
      }
    });

    track('finished', {
      response_type: this.state.responseType,
      departement: this.state.departement,
      finish_timestamp: new Date().toISOString(),
      load_timestamp: this.state.load_timestamp,
      start_timestamp: this.state.start_timestamp,
      ...formData,
    });
  }

  render() {
    return (
      <div>
        <div ref={ref => (this.elem = ref)} />
      </div>
    );
  }
}

const addTag = (cf, tag) => {
  cf.chatList.createResponse(true, tag, tag['cf-questions']);
};

const departementNumRaw = {
  '2': '03 23 20 23 40',
  '3': '04 70 20 03 33',
  '4': '04 92 32 16 56',
  '5': '04 92 52 33 32',
  '6': '04 97 13 56 77',
  '8': '03 24 37 75 20',
  '9': '07 80 48 97 22',
  '10': '06 83 18 24 73',
  '11': '04 68 26 52 97',
  '12': '05 65 60 70 97',
  '14': '02 31 86 13 72',
  '15': '04 71 48 52 67',
  '16': '07 80 48 98 08',
  '18': '07 80 45 62 45',
  '19': '06 14 75 92 45',
  '2A': '04 95 23 43 22',
  '2B': '04 95 34 27 79',
  '23': '09 60 40 44 80',
  '24': '06 66 70 15 59',
  '26': '04 75 41 15 49',
  '27': '02 32 31 60 41',
  '28': '07 49 04 05 93',
  '29': '02 98 90 55 77',
  '30': '04 66 23 66 30',
  '31': '05 61 54 80 80',
  '32': '05 62 05 57 69',
  '34': '04 67 69 75 23',
  '36': '02 54 08 69 14',
  '37': '02 47 05 63 52',
  '38': '04 76 94 09 89',
  '39': '03 84 24 16 62',
  '42': '06 36 08 72 07',
  '44': '02 40 29 40 02',
  '45': '02 38 81 01 09',
  '46': '05 65 22 67 27',
  '47': '05 53 70 95 50',
  '48': '06 82 61 40 14',
  '49': '02 41 87 22 53',
  '51': '09 80 80 06 06',
  '52': '03 25 02 97 64',
  '53': '07 86 29 47 50',
  '54': '09 77 33 94 92',
  '55': '03 29 76 28 97',
  '56': '02 97 63 12 73',
  '57': '03 87 62 74 66',
  '59': '07 69 28 36 92',
  '60': '03 44 09 20 84',
  '61': '06 64 82 78 24',
  '63': '04 73 34 99 01',
  '64': '05 59 80 12 11',
  '65': '05 62 93 09 07',
  '66': '04 68 05 05 43',
  '67': '08 20 69 20 08',
  '68': '03 89 24 34 60',
  '70': '03 84 76 51 18',
  '71': '06 23 85 35 00',
  '75': '07 66 65 69 17',
  '76': '02 35 88 76 33',
  '78': '01 39 51 21 21',
  '79': '05 49 24 97 04',
  '85': '02 51 46 28 83',
  '86': '05 49 41 38 01',
  '87': '05 55 79 01 36',
  '88': '03 29 66 22 40',
  // '91': '01 69 09 21 49',
  '92': '01 47 76 25 42',
  '93': '07 85 29 54 36',
  '94': '01 45 14 66 94',
  '95': '01 34 12 49 68',
  '971': '05 96 61 43 50',
  '972': '05 96 63 27 01',
  '973': '05 94 30 31 01',
  '974': '06 92 69 33 94',
};
var departementNum = {};
_.each(departementNumRaw, function (value, key) {
  departementNum[key.toLowerCase()] = value;
});

const NationalNum = '09 70 500 205';

const departements = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '2A',
  '2B',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
  '60',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '69',
  '70',
  '71',
  '72',
  '73',
  '74',
  '75',
  '76',
  '77',
  '78',
  '79',
  '80',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '88',
  '89',
  '90',
  '91',
  '92',
  '93',
  '94',
  '95',
  '971',
  '972',
  '973',
  '974',
  '976',
].map(x => x.toLowerCase());

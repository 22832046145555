import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Footer from './Footer';
import Form from './Form';
import Navbar from './Navbar';

import '../form.scss';
import './covid.scss';

export default class Embed extends React.Component {
    render() {
        return (
            <div className="covid covid-embed">
                <div className="chat-header">
                    <h1>Covid-19: Chatbot de Orientación</h1>
                </div>
                <div className="chatbot-container" id="chat">
                    <Form />
                </div>
            </div>
        );
    }
}

import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

const Footer = (props) => {
  return (
    <>
      <section>
        <p>
          <Trans>
            Recommendations provided by this tool do not constitute medical advice and should not be
            used to diagnose or treat medical conditions.
          </Trans>
        </p>
      </section>
    </>
  );
};

export default Footer;

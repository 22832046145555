import 'App.css';
import CovidHome from 'covid/Home';
import CovidForm from 'covid/Form';
import Embed from 'covid/Embed';
import Home from 'dentist/Home';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {
  const enConstruction = false;
  if (enConstruction) {
    return (
      <div class="wrapper" style={{ 'text-align': 'center' }}>
        <p>
          <b>En construction</b>
        </p>
      </div>
    );
  }
  return (
    <Router>
      <Switch>
        <Route path="/covid">
          <CovidHome />
        </Route>
        <Route path="/covidchat">
          <Embed />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

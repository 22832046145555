import React, { useState } from 'react';
import { Navbar as ReactNavbar, NavbarBrand } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useTranslation, Trans } from 'react-i18next';

const Navbar = props => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <ReactNavbar expand="md">
        <NavbarBrand className="mx-auto" href="/">
          <h1>
            <Trans>Covid-19 Screening Tool</Trans> <span className="badge badge-light">beta</span>
          </h1>
        </NavbarBrand>
      </ReactNavbar>
      <p className="subtitle">
        <Trans>
          This tool can help you understand what to do next about COVID-19. Let’s all look out for
          each other by knowing our status, trying not to infect others, and reserving care for
          those in need.
        </Trans>
      </p>
    </div>
  );
};

export default withTranslation()(Navbar);

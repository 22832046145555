import axios from 'axios';

export const track = (eventName, data) => {
  const TRACKING_API_URL = 'https://www.spotimplant.com/ud/';
  if (window.location.hostname === 'www.urgence-dentaire.info') {
    // PROD
    axios.post(TRACKING_API_URL, {
      ...generateTrackingData(),
      ...data,
      name: eventName,
    });
    if ('ga' in window && window.ga.getAll) {
      const tracker = window.ga.getAll()[0];
      if (tracker) tracker.send('event', 'chatbot', eventName, JSON.stringify(data));
    }
  }
};

const generateTrackingData = () => ({
  timestamp: new Date().toISOString(),
  document_referrer: window.document.referrer || undefined,
  device_orientation: getOrientation(),
});

function getOrientation() {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName('body')[0];
  const x = w.innerWidth || e.clientWidth || g.clientWidth;
  const y = w.innerHeight || e.clientHeight || g.clientHeight;
  return x > y ? 'landscape' : 'portrait';
}

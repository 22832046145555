import { ConversationalForm } from 'conversational-form';

import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { MultiSelectTag, RobotTag, SelectTag } from 'Tags';
import './i18n';
import _ from 'lodash';

import 'form.scss';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.props.i18n.changeLanguage('es');
    const t = this.props.t;
    this.formFields = [
      new SelectTag(
        'start',
        t(
          "Hello, i'm the Covid-19 screening robot. I'll ask you a few questions to check if you have signs of COVID-19."
        ),
        {},
        [
          {
            label: 'Ok',
            value: 'ok',
          },
        ]
      ).render(),

      new SelectTag('severe', t('Do you experience any of the following?'), {}, [
        {
          label: t('Severe, constant chest pain or pressure'),
          value: 'chest_pain',
        },
        {
          label: t('Extreme difficulty breathing'),
          value: 'difficulty_breathing',
        },
        {
          label: t('Severe, constant lightheadedness'),
          value: 'lightheadedness',
        },
        {
          label: t('Serious disorientation or unresponsiveness'),
          value: 'disorientation',
        },
        {
          label: t('I do not have any of these'),
          value: 'none',
        },
      ]).render(),
      new RobotTag(
        'red_emergency',
        t(
          '<b>This is an emergency</b>. Based on your reported symptoms, you should seek care immediately.'
        ),
        {
          and: {
            severe: 'chest_pain||difficulty_breathing||lightheadedness||disorientation',
          },
        }
      ).render(),
      new SelectTag(
        'age_group',
        t('How old are you?'),
        {
          and: {
            severe: 'none',
          },
        },
        [
          {
            label: t('Under 18'),
            value: 'under_18',
          },
          {
            label: t('Between 18 and 64'),
            value: '18_to_64',
          },
          {
            label: t('65 or older'),
            value: '65_or_older',
          },
        ]
      ).render(),
      new MultiSelectTag(
        'symptoms',
        t('Are you experiencing any of these symptoms? (select all that apply)'),
        {
          and: {
            severe: 'none',
          },
        },
        [
          {
            label: t('Fever, chills, or sweating'),
            value: 'fever',
          },
          {
            label: t('Difficulty breathing (not severe)'),
            value: 'difficulty_breathing',
          },
          {
            label: t('New or worsening cough'),
            value: 'cough',
          },
          {
            label: t('None of the above'),
            value: 'none',
          },
        ]
      ).render(),
      new MultiSelectTag(
        'symptoms_2',
        t('And what about these symptoms?'),
        {
          and: {
            severe: 'none',
          },
        },
        [
          {
            label: t('Sore throat'),
            value: 'sore_throat',
          },
          {
            label: t('Aching throughout the body'),
            value: 'body_aching',
          },
          {
            label: t('Vomiting or diarrhea'),
            value: 'vomiting_diarrhea',
          },
          {
            label: t('None of the above'),
            value: 'none',
          },
        ]
      ).render(),

      new MultiSelectTag(
        'precondition',
        t('Do you have any of these conditions?'),
        {
          and: {
            severe: 'none',
          },
        },
        [
          {
            label: t('Asthma or chronic lung disease'),
            value: 'asthma_or_lung_disease',
          },
          {
            label: t('Pregnancy'),
            value: 'pregnancy',
          },
          {
            label: t('Diabetes with complications'),
            value: 'diabetes',
          },
          // {
          //   label: t('Diseases or conditions that make it harder to cough'),
          //   value: 'hard_to_cough',
          // },
          {
            label: t('Kidney failure that needs dialysis'),
            value: 'kidney',
          },
          // {
          //   label: t('Extreme obesity'),
          //   value: 'obesity',
          // },
          {
            label: t('None of the above'),
            value: 'none',
          },
        ]
      ).render(),
      new MultiSelectTag(
        'precondition_2',
        t('And what about these conditions?'),
        {
          and: {
            severe: 'none',
          },
        },
        [
          {
            label: t('Cirrhosis of the liver'),
            value: 'cirrhosis',
          },
          {
            label: t('Weakened immune system'),
            value: 'weak_immune',
          },
          {
            label: t('Congestive heart failure'),
            value: 'heart',
          },
          {
            label: t('None of the above'),
            value: 'none',
          },
        ]
      ).render(),

      new SelectTag(
        'travel',
        t('In the last 14 days, have you traveled internationally?'),
        {
          and: {
            severe: 'none',
          },
        },
        [
          {
            label: t('Yes'),
            value: 'yes',
          },
          {
            label: t('No'),
            value: 'no',
          },
        ]
      ).render(),
      new SelectTag(
        'area',
        t('In the last 14 days, have you been in an area where COVID-19 is widespread?'),
        {
          and: {
            severe: 'none',
          },
        },
        [
          {
            label: t('I live in an area where COVID-19 is widespread'),
            value: 'live_covid_area',
          },
          {
            label: t('I have visited an area where COVID-19 is widespread'),
            value: 'visit_covid_ara',
          },
          {
            label: t('I don’t know'),
            value: 'dont_know',
          },
        ]
      ).render(),
      new SelectTag(
        'exposure',
        t('In the last 14 days, what is your exposure to others who are known to have COVID-19?'),
        {
          and: {
            severe: 'none',
          },
        },
        [
          {
            label: t('I live with someone who has COVID-19'),
            value: 'live_with',
          },
          {
            label: t('I’ve had close contact with someone who has COVID-19'),
            value: 'close',
          },
          {
            label: t('I’ve been near someone who has COVID-19'),
            value: 'near',
          },
          {
            label: t('I’ve had no exposure'),
            value: 'no',
          },
        ]
      ).render(),
    ];

    this.submitCallback = this.submitCallback.bind(this);
    this.onStepCallback = this.onStepCallback.bind(this);
    this.state = {
      load_timestamp: new Date().toISOString(),
      start_timestamp: undefined,
    };
  }

  componentDidMount() {
    this.cf = ConversationalForm.startTheConversation({
      options: {
        submitCallback: this.submitCallback,
        flowStepCallback: this.onStepCallback,
        preventAutoFocus: true,
        // loadExternalStyleSheet: false
        robotImage: 'doctor.png',
        userImage: 'user.svg',
        dictionaryData: {
          'input-placeholder': 'Veuillez entrer votre réponse...',
          'input-placeholder-error': 'Réponse non valide',
        },
      },
      tags: this.formFields,
    });
    this.elem.appendChild(this.cf.el);
  }

  onStepCallback(dto, success, error) {
    const t = this.props.t;
    if (dto.tag.name === 'exposure') {
      const formData = this.cf.getFormData(true);
      // _.each(formData, function (value, key) {
      //   formData[key] = value[0];
      // });
      const tags = [];

      if (formData.symptoms[0] === 'none' && formData.symptoms_2[0] === 'none') {
        // blue

        tags.push(
          new RobotTag(
            'blue_resp',
            t(
              'You should practice social distancing.&&Help stop the spread. When outside the home, stay at least six feet away from other people, avoid groups, and only use public transit if necessary.'
            ),
            {}
          ).render()
        );
      } else if (
        (formData.symptoms[0] !== 'none' || formData.symptoms_2[0] === 'none') &&
        formData.precondition[0] === 'none' &&
        formData.precondition_2[0] === 'none'
      ) {
        // orange

        tags.push(
          new RobotTag(
            'orange_resp',
            t(
              'You Should self-isolate.&&Based on your answers, you should stay home and away from others. If you can, have a room and bathroom that’s just for you. This can be hard when you’re not feeling well, but it will protect those around you.'
            ),
            {}
          ).render()
        );
      } else {
        tags.push(
          new RobotTag(
            'red_resp',
            t(
              'Contact your Healthcare provider.&&Your answers suggest you should talk to a medical professional about getting tested for COVID-19.'
            ),
            {}
          ).render()
        );
      }
      this.cf.addTags(tags, true);
    }
    return success();
  }

  submitCallback() {
    // const formData = this.cf.getFormData(true);
    // _.each(formData, function (value, key) {
    //   formData[key] = value[0];
    //   if (key === 'echelle_douleur') {
    //     formData[key] = parseInt(value[0], 10);
    //   }
    //   if (key === 'departement') {
    //     formData[key] = value;
    //   }
    // });
    // track('finished', {
    //   response_type: this.state.responseType,
    //   departement: this.state.departement,
    //   finish_timestamp: new Date().toISOString(),
    //   load_timestamp: this.state.load_timestamp,
    //   start_timestamp: this.state.start_timestamp,
    //   ...formData,
    // });
  }

  render() {
    return (
      <div>
        <div ref={(ref) => (this.elem = ref)} />
      </div>
    );
  }
}

export default withTranslation()(Form);

import React from 'react';

const Footer = (props) => {
  return (
    <>
      <section>
        <h2>Informations Importantes</h2>
        <p>Selon le communiqué de presse diffusé par l’ordre national des chirurgiens-dentistes le 28 octobre 2020, <b>les cabinets dentaires vont rester ouverts normalement en semaine</b>. Les dimanches et jours fériés, un service de garde sera organisé comme en temps normal. <a href="https://www.facebook.com/ordrenationaldeschirurgiensdentistes/posts/2642924992635531">Le communiqué de presse est consultable ici</a>.</p>
        <p>Pour information les cabinets dentaires ont pu s’approvisionner en matériel de protection (masques FFP2, charlottes, surblouses etc.) afin de prendre en charge leurs patients dans le respect des mesures sanitaires les plus strictes en vigueur. Il est important que les patients continuent à consulter leur dentiste traitant pour éviter la saturation des urgences hospitalières.</p>

        <p>Voici la marche à suivre pour obtenir un rendez-vous en urgence selon le jour de la semaine :</p>
        <b>Du lundi au samedi :</b>
        <ul>
          <li>Appelez votre dentiste traitant en priorité, il faut parfois insister ou bien laisser des messages sur le répondeur pour qu’il vous contacte.</li>
          <li>Si vous n’avez pas de dentiste traitant : essayez un dentiste de votre zone géographique.</li>
          <li>Si aucun dentiste n’est disponible pour vous recevoir : vous pouvez vous rendre dans un service hospitalier dentaire à proximité ou bien aux urgences générales d’un hôpital</li>
        </ul>
        <b>Dimanche ou jour férié :</b>
        <ul>
          <li>Les cabinets dentaires libéraux ne sont pas ouverts.</li>
          <li>Consultez les dentistes de garde sur le site du conseil de l'ordre départemental des Chirurgiens-Dentistes ou bien téléphonez au numéro du conseil de l’ordre départemental qui devrait indiquer les dentistes de garde.</li>
          <li>Si aucun dentiste n’est disponible pour vous recevoir : vous pouvez vous rendre dans un service hospitalier dentaire à proximité ou bien aux urgences générales d’un hôpital</li>
        </ul>
        <p>
          Attention : Les rendez-vous chez les praticiens de gardes sont régulés par les Conseils de
          l’Ordre des Chirurgiens-Dentistes situés dans chaque département. <b>Adressez-vous en priorité à votre dentiste traitant !</b>
        </p>
      </section>
      <section>
        <h2>À propos</h2>
        <p>
          <a href="https://www.urgence-dentaire.info">Urgence-dentaire.info</a> est un site
          informatif, conçu par <a href="http://spotimplant.com/">Spotimplant.com</a> grâce à une équipe constituée de
          chirurgiens-dentistes, de médecins généralistes et d’ingénieurs en informatique ayant souhaité
          contribuer à la lutte contre le COVID-19.
        </p>
        <p>
          Ce site suit les directives du Conseil de l’Ordre National des Chirurgiens-Dentistes de
          France (1)(2)(3). Il a pour objectif de désengorger les standards téléphoniques d’urgence
          (en particulier le 15), en vous aidant à qualifier au mieux votre besoin en soins
          dentaires et en vous orientant directement vers le bon interlocuteur.
        </p>

        <p>
          Cet outil ne constitue en aucun cas un diagnostic médical, en cas de doute ou de question
          supplémentaire, veuillez vous adresser à votre dentiste traitant habituel.
        </p>
        <span>
          <b>Liens utiles</b>
        </span>
        <ul>
          <li>
            <a href="https://www.facebook.com/ordrenationaldeschirurgiensdentistes/posts/2642924992635531">Communiqué de presse du conseil de l'ordre national des Chirurgiens-Dentistes du 28 octobre 2020.</a>
          </li>
          <li>
            <a href="https://solidarites-sante.gouv.fr/">solidarites-sante.gouv.fr</a>
          </li>
          <li>
            <a href="https://www.gouvernement.fr/info-coronavirus">
              gouvernement.fr/info-coronavirus
            </a>
          </li>
        </ul>

        <span>
          <b>Références</b>
        </span>
        <ol>
          <li>ADF : Schéma élaboré à partir d’une publication, E.PEGON-MACHAT ET COLL</li>
          <li>
            Collège National des Chirurgiens-Dentistes Universitaires en Santé Publique (CNCDUSP)
            -"Risques et recommandations pour les soins bucco-dentaires dans le contexte d'épidémie
            au Coronavirus" - 16 mars 2020 - Damien OFFNER, Elisabetta MERIGO, Delphine TARDIVO,
            Laurence LUPI et Anne-Marie MUSSET
          </li>
          <li>
            RECOMMANDATIONS D’EXPERTS POUR LA PRISE EN CHARGE DES PATIENTS EN CABINET DENTAIRE DE
            VILLE AU STADE 3 DE L’EPIDEMIE DE COVID-19 Rédigé sous l’égide de l’Ordre national des
            Chirurgiens-Dentistes
          </li>
        </ol>
        <span>
          <b>Contact</b>
        </span>
        <p>
          <Contactus />
        </p>
        {/* <p>
          <ContactCDF />
        </p> */}
      </section>
    </>
  );
};

export default Footer;

class Contactus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      ...this.state,
      opened: true,
    });
  }

  render() {
    return (
      <div class="contact-us">
        {this.state.opened === false ? (
          <button className="btn btn-link" onClick={this.onClick}>
            Contacter l'équipe du site
          </button>
        ) : (
            <span>contact@urgence-dentaire.info</span>
          )}

        <br />
        <small>
          (Uniquement pour des questions liées au site, pour une question médicale veuillez vous
          adresser à votre dentiste)
        </small>
      </div>
    );
  }
}

class ContactCDF extends Contactus {
  render() {
    return (
      <div class="contact-us">
        {this.state.opened === false ? (
          <button className="btn btn-link" onClick={this.onClick}>
            Contacter les CDF
          </button>
        ) : (
            <span>covid19@lescdf.fr</span>
          )}
      </div>
    );
  }
}

import _ from 'lodash';

const formatConditionals = conditionals => {
  const formattedConditionals = {};
  _.each(conditionals, function (value, key) {
    formattedConditionals[`cf-conditional-${key}`] = value;
  });
  return formattedConditionals;
};

const formatOrConditionals = conditionals => {
  const formattedConditionals = {};
  _.each(conditionals, function (value, key) {
    formattedConditionals[`cf-or-conditional-${key}`] = value;
  });
  return formattedConditionals;
};

class Tag {
  constructor(name, question, conditions = { and: {}, or: {} }) {
    this.name = name;
    this.question = question;
    this.conditions = conditions;
  }
  render() { }

  static create(...args) {
    return new Tag(...args).render();
  }

  formatConditionals() {
    return {
      ...formatConditionals(this.conditions.and),
      ...formatOrConditionals(this.conditions.or),
    };
  }
}

export class BooleanTag extends Tag {
  constructor(name, question, conditions = { and: {}, or: {} }) {
    super(name, question, conditions);
  }
  static create(...args) {
    return new BooleanTag(...args).render();
  }
  render() {
    const resp = {
      tag: 'select',
      name: this.name,
      'cf-questions': this.question,
      ...this.formatConditionals(),
      children: [
        {
          tag: 'option',
          'cf-label': 'Oui',
          value: true,
        },
        {
          tag: 'option',
          'cf-label': 'Non',
          value: false,
        },
      ],
    };
    return resp;
  }
}

export class SelectTag extends Tag {
  constructor(name, question, conditions = { and: {}, or: {} }, options = []) {
    super(name, question, conditions);
    this.options = options;
  }

  formatOptions() {
    this.options.map((option) => ({
      tag: 'option',
      'cf-label': option.label,
      value: option.value,
    }));
  }

  render() {
    const resp = {
      tag: 'select',
      name: this.name,
      'cf-questions': this.question,
      ...this.formatConditionals(),
      children: this.options.map((option) => ({
        tag: 'option',
        'cf-label': option.label,
        value: option.value,
      })),
    };
    console.log('$$ select', resp);

    return resp;
  }
}

export class MultiSelectTag extends Tag {
  constructor(name, question, conditions = { and: {}, or: {} }, options = []) {
    super(name, question, conditions);
    this.options = options;
  }

  render() {
    const resp = {
      tag: 'select',
      type: 'Checkboxes',
      name: this.name,
      'cf-questions': this.question,
      ...this.formatConditionals(),
      isMultiChoice: true,
      multiple: true,
      children: this.options.map((option) => ({
        tag: 'option',
        type: 'checkbox',
        'cf-label': option.label,
        value: option.value,
      })),
    };
    console.log('$$ rendered multi', resp);
    return resp;
  }
}

export class RobotTag extends Tag {
  render() {
    const resp = {
      tag: 'cf-robot-message',
      name: this.name,
      'cf-questions': this.question,
      ...this.formatConditionals(),
    };
    return resp;
  }
}

// Specific Tags
export class BlueResponseTag extends RobotTag {
  constructor(conditions = { and: {}, or: {} }) {
    const question =
      'En temps de crise sanitaire et de lutte contre le virus COVID-19, votre demande <b>ne semble a priori pas</b> constituer un motif suffisamment urgent pour nécessiter une prise en charge rapide.&&Nous vous conseillons de bien vouloir <b>contacter votre dentiste traitant qui pourra vous proposer un rendez-vous pour vous prendre en charge</b>.';
    super('blue_resp', question, conditions);
  }
}

export class OrangeResponseTag extends RobotTag {
  constructor(conditions = { and: {}, or: {} }) {
    const question =
      'En temps de crise sanitaire et de lutte contre le virus COVID-19, votre demande semble constituer un motif <b>nécessitant l’avis d’un professionnel de santé</b>.&&Nous vous conseillons de <b>joindre votre dentiste traitant</b> qui pourra juger de votre situation et faire le nécessaire pour votre prise en charge.';
    super('orange_resp', question, conditions);
  }
}

export class RedResponseTag extends RobotTag {
  constructor(conditions = { and: {}, or: {} }) {
    const question =
      'En temps de crise sanitaire et de lutte contre le virus COVID-19, votre demande semble constituer un motif <b>nécessitant l’avis d’un professionnel de santé</b>.&&Nous vous prions de <b>joindre votre dentiste traitant afin qu’il organise votre prise en charge le plus rapidement possible.</b>';
    super('red_resp', question, conditions);
  }
}

export class DouleurTag extends SelectTag {
  constructor(name, question, conditions = { and: {}, or: {} }) {
    const options = [
      {
        label: 'Tout le temps',
        value: 'tout_le_temps',
      },
      {
        label: 'De temps en temps ou en mangeant',
        value: 'temps_en_temps',
      },
      {
        label: "Je n'ai pas mal",
        value: 'pas_mal',
      },
    ];

    super(name, question, conditions, options);
  }
}

export class DouleurScaleTag extends SelectTag {
  constructor(name, question, conditions = { and: {}, or: {} }) {
    const options = [...Array(10).keys()].map(n => ({
      label: `${n + 1}`,
      value: n,
    }));

    super(name, question, conditions, options);
  }
}

export class TextInputTag extends Tag {
  constructor(
    name,
    question,
    conditions = { and: {}, or: {} },
    placeholder = undefined,
    error = undefined
  ) {
    super(name, question, conditions);
    this.placeholder = placeholder;
    this.error = error;
  }
  render() {
    const resp = Object.assign(
      {},
      {
        tag: 'input',
        type: 'text',
        name: this.name,
        'cf-questions': this.question,

        ...this.formatConditionals(),
      },
      this.placeholder
        ? {
          'cf-input-placeholder': this.placeholder,
        }
        : {},
      this.error
        ? {
          'cf-error': this.error,
        }
        : {}
    );
    return resp;
  }
}

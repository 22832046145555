import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Footer from './Footer';
import Form from './Form';
import Navbar from './Navbar';

export default class Home extends React.Component {
  render() {
    return (
      <div>
        <div className="logos">
          {/* <label className="text-muted">En collaboration avec:</label>
          <a href="https://lescdf.fr/">
            <img src="logo-cdf.png" className="cdf" alt="logo cdf" />
          </a> */}
          <a href="http://spotimplant.com/">
            <img src="spotimplant-logo-v2.png" className="spotimplant" alt="logo spotimplant" />
          </a>
        </div>
        <div class="wrapper">
          <Navbar></Navbar>
          <main>
            <div class="anchor-link-container">
              <AnchorLink class="btn btn-primary" href="#chat">
                Commencer
              </AnchorLink>
            </div>
            <div className="form-container chatbot-container" id="chat">
              <Form />
            </div>
            <Footer />
          </main>
        </div>
      </div>
    );
  }
}

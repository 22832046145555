import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          'To get started, edit <1>src/App.js</1> and save to reload.':
            'To get started, edit <1>src/App.js</1> and save to reload.',
          'Welcome to React': 'Welcome to React and react-i18next',
          welcome: 'Hello <br/> <strong>World</strong>',
        },
      },
      es: {
        translations: {
          'Covid-19 Screening Tool': 'Covid-19 Herramienta de Orientación',
          'This tool can help you understand what to do next about COVID-19. Let’s all look out for each other by knowing our status, trying not to infect others, and reserving care for those in need.':
            'Esta herramienta ayuda a tener una mejor comprensión sobre que hacer frente al COVID-19. Cuidémonos mutuamente conociendo nuestro estado, tratando de no infectar a otros y reservando las atenciones para los casos más urgentes.',
          'Recommendations provided by this tool do not constitute medical advice and should not be used to diagnose or treat medical conditions.':
            'Las recomendaciones proporcionadas por esta herramienta no constituyen consejo médico y no deben usarse para diagnosticar o tratar afecciones médicas.',
          "Hello, i'm the Covid-19 screening robot. I'll ask you a few questions to check if you have signs of COVID-19.":
            'Hola, soy el chatbot del covid-19. Te voy a hacer unas cuantas preguntas para chequear si tienes algunos síntomas del Covid-19.',
          'Do you experience any of the following?':
            '¿Experimentas cualquiera de los siguientes síntomas?',
          'Severe, constant chest pain or pressure':
            'Fuerte dolor de pecho constante o de alta presión',
          'Extreme difficulty breathing': 'Dificultad extrema para respirar',
          'Severe, constant lightheadedness': 'Mareo constante y severo',
          'Serious disorientation or unresponsiveness': 'Desorientación grave o falta de respuesta',
          'I do not have any of these': 'No tengo ninguno de esos síntomas',
          '<b>This is an emergency</b>. Based on your reported symptoms, you should seek care immediately.':
            '<B> Esto es una emergencia </ b>. En base a los síntomas reportados debes buscar atención médica de inmediato.',
          'How old are you?': '¿Cuantos años tienes?',
          'Under 18': 'Menos de 18 años',
          'Between 18 and 64': 'Entre 18 y 64',
          '65 or older': '65 años o más',
          'Are you experiencing any of these symptoms? (select all that apply)':
            '¿Estás experimentando cualquiera de estos síntomas? (seleccione todos las que correspondan)',
          'Fever, chills, or sweating': 'Fiebre, escalofríos o sudoración',
          'Difficulty breathing (not severe)': 'Dificultad para respirar (no grave)',
          'New or worsening cough': 'Tos nueva o empeoramiento',
          'Sore throat': 'Dolor de garganta',
          'Aching throughout the body': 'Dolor de todo el cuerpo',
          'Vomiting or diarrhea': 'Vómitos o diarrea',
          'None of the above': 'Ninguna de las anteriores',
          'And what about these symptoms?': '¿Y estos síntomas?',
          'Do you have any of these conditions?': '¿Tienes alguna de estas condiciones?',
          'Asthma or chronic lung disease': 'Asma o enfermedad pulmonar crónica',
          Pregnancy: 'Embarazo',
          'Diabetes with complications': 'Diabetes con complicaciones',
          'Diseases or conditions that make it harder to cough':
            'Enfermedades o condiciones que hacen que sea más difícil toser',
          'Kidney failure that needs dialysis': 'Insuficiencia renal que requiere diálisis',
          'Cirrhosis of the liver': 'Cirrosis del higado',
          'Weakened immune system': 'El sistema inmunológico debilitado',
          'Congestive heart failure': 'Insuficiencia cardíaca congestiva',
          'Extreme obesity': 'Obesidad extrema',
          'And what about these conditions?': '¿Y estas condiciones?',
          'In the last 14 days, have you traveled internationally?':
            'En los últimos 14 días, ¿ha viajado internacionalmente?',
          'I have traveled internationally': 'He viajado internacionalmente',
          'I have not traveled internationally': 'No he viajado internacionalmente',
          'In the last 14 days, have you been in an area where COVID-19 is widespread?':
            'En los últimos 14 días, ¿ha estado en un área donde COVID-19 está muy extendido?',
          'I live in an area where COVID-19 is widespread':
            'Yo vivo en una zona donde COVID-19 está muy extendido',
          'I have visited an area where COVID-19 is widespread':
            'He visitado un área donde COVID-19 está muy extendido',
          'I don’t know': 'No lo sé',
          'In the last 14 days, what is your exposure to others who are known to have COVID-19?':
            'En los últimos 14 días, ¿cuál ha sido tu exposición a otras personas que se sabe que tienen COVID-19?',
          'I live with someone who has COVID-19': 'Yo vivo con alguien que tiene COVID-19',
          'I’ve had close contact with someone who has COVID-19':
            'He tenido contacto cercano con alguien que tiene COVID-19',
          'I’ve had close contact with someone who has COVID-19 (For 10 minutes, I was within 6 feet of someone who’s sick or exposed to a cough or sneeze.)':
            'He tenido contacto cercano con alguien que tiene COVID-19 (por 10 minutos, estaba a menos de 2 metros de alguien que está enfermo o expuesto a la tos o los estornudos.)',
          'I’ve been near someone who has COVID-19 (I was at least 6 feet away and was not exposed to a sneeze or cough.)':
            'He estado cerca de alguien que tiene COVID-19 (que estaba al menos a 2 metros de distancia y no estaba expuesto a un estornudo o tos).',
          'I’ve been near someone who has COVID-19':
            'He estado cerca de alguien que tiene COVID-19',
          'I’ve had no exposure (I have not been in contact with someone who’s sick.)':
            'No he tenido exposición (no he estado en contacto con una persona que está enferma.)',
          'I’ve had no exposure': 'No he tenido exposición',
          'Do you live or work in a care facility? This includes a hospital, emergency room, other medical setting, or long-term facility.':
            '¿Vives o trabajas en un centro de atención? Esto incluye un hospital, sala de urgencias, otro establecimiento médico.',
          'I live in a long-term care facility (including nursing homes or assisted living)':
            'Yo vivo en un centro de atención médica o asilo (incluyendo hogares de ancianos o de vida asistida)',
          'I have worked in a hospital or other care facility in the past 14 days':
            'He trabajado en un hospital o otro tipo de centro de atención en los últimos 14 días',
          'I plan to work in a hospital or other care facility in the next 14 days':
            'Tengo la intención de trabajar en un hospital o otro tipo de atención en los próximos 14 días',
          'No, I don’t live or work in a care facility':
            'No, no vivo o trabajo en un centro de atención',
          '': '',
          'Contact Your Healthcare Provider': 'Contacta a tu médico',
          'Your answers suggest you should talk to a medical professional about getting tested for COVID-19.':
            'Tus respuestas sugieren que debes hablar con un profesional médico acerca de hacerte la prueba de detección del COVID-19.',
          'Contact your Healthcare provider.&&Your answers suggest you should talk to a medical professional about getting tested for COVID-19.':
            'Contacta a tu médico.&&Tus respuestas sugieren que debes hablar con un profesional médico acerca de hacerte la prueba de detección del COVID-19.',
          'You Should Self-Isolate': 'Debes aislarte y realizar una cuarentena',
          'Based on your answers, you should stay home and away from others. If you can, have a room and bathroom that’s just for you. This can be hard when you’re not feeling well, but it will protect those around you.':
            'Con base en sus respuestas, debe quedarse en casa y lejos de los demás. Si es posible, tener una habitación y un baño es sólo para ti. Esto puede ser difícil, pero solo así protegeras a las personas que te rodean.',
          'You Should self-isolate.&&Based on your answers, you should stay home and away from others. If you can, have a room and bathroom that’s just for you. This can be hard when you’re not feeling well, but it will protect those around you.':
            'Debes aislarte y realizar una cuarentena.&&Con base en sus respuestas, debe quedarse en casa y lejos de los demás. Si es posible, tener una habitación y un baño es sólo para ti. Esto puede ser difícil, pero solo así protegeras a las personas que te rodean.',
          'You should practice social distancing.&&Help stop the spread. When outside the home, stay at least six feet away from other people, avoid groups, and only use public transit if necessary.':
            'Debes aislarte y realizar una cuarentena.&&Con base en sus respuestas, debe quedarse en casa y lejos de los demás. Si es posible, tener una habitación y un baño es sólo para ti. Esto puede ser difícil, pero solo así protegeras a las personas que te rodean.',
          'You should practice social distancing': 'Debe practicar el Distanciamiento social',
          'Help stop the spread. When outside the home, stay at least six feet away from other people, avoid groups, and only use public transit if necessary.':
            'Ayuda por favor a detener la propagación. Cuando estas fuera de tu casa, quedate por lo menos a 2 metros de distancia de otras personas y EVITA grupos o aglomeraciones.',
          'No Test at This Time': 'No hay prueba disponible en ese momento',
          'As of now, your answers suggest you do not need to get tested. If anything changes, take the questionnaire again.':
            'A partir de ahora, tus respuestas sugieren que no es necesario hacerte una prueba. Si nada cambia, resuelve de nuevo el cuestionario.',
          'Your Next Steps': 'Tus siguientes pasos',
          'Isolate From Others': 'Aislate De Otros',
          'You should try to stay away from others for at least 7 days from when your symptoms first appeared. Your isolation can end if your symptoms improve significantly and if you have had no fever for at least 72 hours without the use of medicine. By isolating yourself, you can slow the spread of COVID-19 and protect others.':
            'Debes tratar de mantenerte alejada(o) de otras personas durante al menos 7 días a partir de cuando los síntomas aparecieron por primera vez. Tu aislamiento puede terminar si tus síntomas mejoran significativamente y si no ha tenido fiebre por lo menos 72 horas sin el uso de medicina. Al aislarte a tí mismo, ayudas a disminuir la propagación del COVID-19 y a proteger a los demás.',
          'Rest and Take Care': 'Descansa y cuidate',
          'Eat well, drink fluids, and get plenty of rest.':
            'Comer bien, beber líquidos y descansar mucho.',
          'Call Your Work Health Provider': 'Llama al centro de salud especializado en tu país',
          'You should notify your work of your current symptoms as quickly as you can. This is vital to slowing the spread of COVID-19.':
            'Debes notificar a tu centro de labores de tus síntomas actuales tan pronto como sea posible. Esto es vital para reducir la propagación del COVID-19.',
          'Monitor Symptoms': 'Los síntomas del monitor',
          'Watch for COVID-19 symptoms such as cough, fever, and difficulty breathing. If your symptoms get worse, contact your doctor’s office.':
            'Quédate atento(a) a los síntomas del COVID-19 tales como tos, fiebre y dificultad para respirar. Si tus síntomas empeoran, pónte en contacto con tu médico o la entidad oficial en tu país.',
          'Talk to Someone About Testing': 'Habla con alguien acerca del test COVID-19.',
          'Your answers suggest you may need to get tested for COVID-19. You should get in touch with your doctor’s office or your state or local health department for more information. Testing access may vary by location and provider.':
            'Tus respuestas sugieren que puede que tengas que hacerte la prueba del COVID-19. Debes ponerte en contacto con tu médico o el departamento de salud estatal o local para más información. El acceso a las pruebas de COVID-19 puede variar según la ubicación y el proveedor.',
          Yes: 'Si',
        },
      },
    },
    fallbackLng: 'en',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

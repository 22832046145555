import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Footer from './Footer';
import Form from './Form';
import Navbar from './Navbar';

import './covid.scss';

export default class Home extends React.Component {
  render() {
    return (
      <div className="covid">
        <div className="wrapper">
          <Navbar></Navbar>
          <main>
            <div className="anchor-link-container">
              <AnchorLink className="btn btn-primary" href="#chat">
                Commencer
              </AnchorLink>
            </div>
            <div className="form-container" id="chat">
              <Form />
            </div>
            <Footer />
          </main>
        </div>
      </div>
    );
  }
}
